import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet'
import { useCallback, useRef } from 'react'
import { useAppStore } from '../../store/useAppStore'
import AdvancedFilterView from './GridFilterAdvancedView'
import GridFilterView from './GridFilterBasicView'

interface Props {
  open: boolean
  onClose: () => void
}

const GridFilterSheet = ({ open, onClose }: Props) => {
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const basicFilters = useAppStore.use.basicFilters()
  const advancedFilters = useAppStore.use.advancedFilters()

  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        disappearsOnIndex={-1}
        pressBehavior={'close'}
      />
    ),
    []
  )

  if (open) {
    bottomSheetModalRef.current?.present()
  } else {
    bottomSheetModalRef.current?.dismiss()
  }

  return (
    <BottomSheetModal
      backdropComponent={renderBackdrop}
      enableDismissOnClose={true}
      keyboardBlurBehavior="restore"
      onDismiss={onClose}
      ref={bottomSheetModalRef}
    >
      {advancedFilters.isActive && <AdvancedFilterView />}
      {basicFilters.isActive && <GridFilterView />}
    </BottomSheetModal>
  )
}

export default GridFilterSheet
